var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "userChangeContent",
            fn: function(row) {
              return _c("div", {}, [
                row.examineType === 1
                  ? _c("div", [_vm._v(_vm._s(row.content))])
                  : _vm._e(),
                row.examineType === 2
                  ? _c("img", {
                      staticClass: "avatar-image",
                      attrs: { src: row.content, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handlePreviewImg(row.content)
                        }
                      }
                    })
                  : _vm._e()
              ])
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                { staticClass: "setup" },
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "Are you sure delete this item?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.unconfirm(row)
                        }
                      }
                    },
                    [
                      row.examineStatus == 0
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { size: "small", type: "danger" }
                            },
                            [_vm._v("拒绝")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "Are you sure delete this item?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.confirm(row)
                        }
                      }
                    },
                    [
                      row.examineStatus == 0
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { size: "small", type: "primary" }
                            },
                            [_vm._v("同意")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure resetting this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.confirm(row, "reset")
                            }
                          }
                        },
                        [
                          _c("a-button", { attrs: { size: "small" } }, [
                            _vm._v("重置")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }