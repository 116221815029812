<template>
  <div>
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :rowKey="(record, index) => index"
    >
      <!-- 审核头像 -->
      <div slot="userChangeContent" slot-scope="row">
        <div v-if="row.examineType === 1">{{ row.content }}</div>
        <img v-if="row.examineType === 2" :src="row.content" alt="" class="avatar-image" @click="handlePreviewImg(row.content)">
      </div>
      <div
          slot="setup"
          slot-scope="row"
          class="setup"
      >

        <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="unconfirm(row)"
        >
          <a-button
              v-if="row.examineStatus == 0"
              size="small"
              style="margin-right: 5px"
              type="danger"
          >拒绝</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="confirm(row)"
        >
          <a-button
              size="small"
              v-if="row.examineStatus == 0"
              style="margin-right: 5px"
              type="primary"
          >同意</a-button>
        </a-popconfirm>
        <div style="margin-top: 10px;">
          <a-popconfirm
              title="Are you sure resetting this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="confirm(row, 'reset')"
          >
            <a-button
                size="small"
            >重置</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
export default {
  props: ["loading", "pagination", "list"],
  data() {
    return {
      columns: [
        {
          title: "id",
          align: "center",
          dataIndex: "id",
        },
        {
          title: "userId",
          align: "center",
          dataIndex: "userId",
        },
        {
          title: "修改内容",
          align: "center",
          scopedSlots: { customRender: "userChangeContent" },
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "examineStatus",
          customRender: (text) => {
            switch (text) {
              case -1:
                return <span style="color: red">未通过</span>;
              case 0:
                return <span style="color: dodgerblue">待审核</span>;
              case 1:
                return <span style="color: green">已通过</span>;
              default:
                break;
            }
          },
        },
        {
          title: "审核类型",
          align: "center",
          dataIndex: "reviewType",
          customRender: (text) => {
            switch (text) {
              case 0:
                return <span style="color: red">未区分</span>;
              case 1:
                return <span style="color: dodgerblue">正常提交审核</span>;
              case 5:
                return <span style="color: green">线下交易免审</span>;
              default:
                break;
            }
          },
        },
        {
          title: "修改时间",
          align: "center",
          dataIndex: 'createTime'
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
    };
  },
  methods: {
    /** 拒绝 */
    unconfirm(row) {
      this.reviewedUserInfo(row.id, -1)
    },
    /** 同意 */
    async confirm(row, type) {
      if (type === 'reset') {
        const res = await this.axios("/dq_admin/userInfoExamine/resetUserInfo", {
          params: {
            examineId: row.id
          },
        })
        if (res.status != 200) return
        this.$message.success(res.message);
        this.$emit("success")
      } else  {
        await this.reviewedUserInfo(row.id, 1)
      }
    },
    // 审核头像
    async reviewedUserInfo(examineId, examineStatus){
      const res = await this.axios("/dq_admin/userInfoExamine/reviewedUserInfo", {
        params: {
          examineId,
          examineStatus
        },
      })
      if (res.status != 200) return
      this.$message.success(res.message);
      this.$emit("success")
    },
    /** 预览图片 */
    handlePreviewImg(url) {
      this.$previewImg({
        list: [{ img_url: url }],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    // 翻页
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style lang="scss">
.avatar-image {
  width: 80px;
  height: 80px;
}
.setup {

}
</style>