<template>
  <div class="page">
    <div class="page-header">
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        size="small"
        style="margin: 5px; min-width: 120px"
        v-model="params.examineType"
        @change="getList()"
        placeholder="类型"
      >
        <a-select-option
          v-for="item of examineTypeList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-input
          allowClear
          v-model="params.userName"
          size="small"
          style="width: 200px; margin-left: 5px"
          placeholder="昵称或userId或店铺名"
      ></a-input>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="margin: 5px; min-width: 120px"
          v-model="params.examineStatus"
          placeholder="状态"
      >
        <a-select-option
            v-for="item of examineStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          size="small"
          style="margin: 5px; min-width: 120px"
          v-model="params.reviewType"
          placeholder="审核类型"
      >
        <a-select-option
            v-for="item of reviewTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button
          @click="search"
          size="small"
          style="margin-left: 10px"
          type="primary"
      >搜索</a-button>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @changePage="changePage"
        @success="getList"
      ></PageTable>
    </div>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/dqPlatform/userinfoexamine/PageTable.vue";
export default {
  data() {
    return {
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        examineType: undefined,
        userName: undefined,
        examineStatus: undefined,
        reviewType: undefined
      },
      loading: false,
      examineTypeList: [
        {name: "头像", value: 2},
        {name: "昵称", value: 1},
      ],
      examineStatusList: [
        {name: "待审核", value: 0},
        {name: "已通过", value: 1},
        {name: "未通过", value: -1},
      ],
      reviewTypeList: [
        {name: "未区分", value: 0},
        {name: "正常提交审核", value: 1},
        {name: "线下交易免审", value: 5},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
    };
  },
  components: {
    PageTable,
  },
   created(){
    this.getList()
  },
  methods:{
    async getList() {
      const res = await this.axios("/dq_admin/userInfoExamine/getExamineUserInfoList", {
        params: this.params,
      })
      this.list = res.data.records
      this.pagination.total = res.data.total
    },
    // 切换分页
    changePage(page){
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    search() {
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
</style>