var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", "min-width": "120px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              size: "small",
              placeholder: "类型"
            },
            on: {
              change: function($event) {
                return _vm.getList()
              }
            },
            model: {
              value: _vm.params.examineType,
              callback: function($$v) {
                _vm.$set(_vm.params, "examineType", $$v)
              },
              expression: "params.examineType"
            }
          },
          _vm._l(_vm.examineTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c("a-input", {
          staticStyle: { width: "200px", "margin-left": "5px" },
          attrs: {
            allowClear: "",
            size: "small",
            placeholder: "昵称或userId或店铺名"
          },
          model: {
            value: _vm.params.userName,
            callback: function($$v) {
              _vm.$set(_vm.params, "userName", $$v)
            },
            expression: "params.userName"
          }
        }),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", "min-width": "120px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              size: "small",
              placeholder: "状态"
            },
            model: {
              value: _vm.params.examineStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "examineStatus", $$v)
              },
              expression: "params.examineStatus"
            }
          },
          _vm._l(_vm.examineStatusList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", "min-width": "120px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              size: "small",
              placeholder: "审核类型"
            },
            model: {
              value: _vm.params.reviewType,
              callback: function($$v) {
                _vm.$set(_vm.params, "reviewType", $$v)
              },
              expression: "params.reviewType"
            }
          },
          _vm._l(_vm.reviewTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-button",
          {
            staticStyle: { "margin-left": "10px" },
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "page-body" },
      [
        _c("PageTable", {
          attrs: {
            list: _vm.list,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: { changePage: _vm.changePage, success: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }